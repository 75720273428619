import axios from "axios";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";


const { createSlice } = require("@reduxjs/toolkit");

const InitialState = {
	data: [],
	total: 0,
};

const pagination = {
	sort: "-createdAt",
	page: 1,
	limit: 10,
};

const aboutSlice = createSlice({
	name: "about",
	initialState: InitialState,
	reducers: {
		setAbout(state, action) {
			state.data = action.payload.about;
			state.total = action.payload.total;
			// //console.log("payload", action.payload.total);
		},
		addAbout(state, action) {
			state.data.unshift(action.payload);
		},
		editAbout(state, action) {
			const index = state.data.findIndex(
				(item) => item._id === action.payload._id
			);
			if (index === -1) state.data.push(action.payload);
			else state.data[index] = action.payload;
		},
	},
});


export const aboutActions = aboutSlice.actions;
export default aboutSlice.reducer;

export const getAbout = (data) => {
	return async (dispatch) => {
		const response = await DataService.get(
			`/about/admin/all?${new URLSearchParams(data).toString()}`
		);
		//console.log("response", response);
		if (response.status >= 200 && response.status < 300)
			dispatch(aboutActions.setAbout(response.data));
		else WarningToast("An error has occur");
	};
};

export const EditAbout = (
	id,
	formData,
	navigate,
	setIsLoading,
	update = false
) => {
	return async (dispatch) => {
		try {
			setIsLoading({ loading: true, progress: 0 });
			if (formData.image?.images) {
				const images = await uploadFileS3([formData.image.images], setIsLoading);
				formData.image = images[0];
			}
			if (formData.ceoSection?.image?.images) {
				const images = await uploadFileS3([formData.ceoSection.image.images], setIsLoading);
				formData.ceoSection.image = images[0];
			}

			if (formData.vicePresident?.image?.images) {
				const images = await uploadFileS3([formData.vicePresident.image.images], setIsLoading);
				formData.vicePresident.image = images[0];
			}

			for (let i = 0; i < formData.teamsSection.length; i++) {
				if (formData.teamsSection[i]?.image?.images) {
					// //console.log("formData.images[i].images", [formData.images[i].images]);
					const images = await uploadFileS3([formData.teamsSection[i].image.images], setIsLoading);
					formData.teamsSection[i].image = images[0];
				}
			}
			let response;
			//console.log("formData", formData);
			if (update) {
				response = await DataService.patch(`/about/admin/${id}`, formData);
			}
			// dispatch(aboutActions.addAbout(response.data));
			SuccessToast("edited");
			setIsLoading({ loading: false, progress: 0 });
			// navigate(-1);
		} catch (error) {
			setIsLoading({ loading: false, progress: 0 });
			WarningToast("An error has occurred");
			//console.log(error);
		}
	};
};

export const deleteAbout = (id) => {
	return async (dispatch) => {
		try {
			const response = await DataService.delete(`/about/admin/${id}`);
			if (response.status >= 200 && response.status < 300) {
				dispatch(getAbout(pagination));
				SuccessToast("delete success");
			} else {
				WarningToast("An error has occur");
			}
		} catch (err) {
			WarningToast("An error has occur");
		}
	};
};