import axios from "axios";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";


const { createSlice } = require("@reduxjs/toolkit");

const InitialState = {
	data: [],
	total: 0,
};

const pagination = {
	sort: "-createdAt",
	page: 1,
	limit: 10,
};

const compoundLocationSlice = createSlice({
	name: "compoundLocation",
	initialState: InitialState,
	reducers: {
		setCompoundLocation(state, action) {
			state.data = action.payload.compoundLocations;
			state.total = action.payload.total;
		},
		addCompoundLocation(state, action) {
			state.data.unshift(action.payload);
		},
		editEventType(state, action) {
			const index = state.data.findIndex(
				(item) => item._id === action.payload._id
			);
			if (index === -1) state.data.push(action.payload);
			else state.data[index] = action.payload;
		},
	},
});


export const compoundLocationActions = compoundLocationSlice.actions;
export default compoundLocationSlice.reducer;

export const getCompoundLocation = (data) => {
	return async (dispatch) => {
		const response = await DataService.get(
			`/compound-location/admin/all?${new URLSearchParams(data).toString()}`
		);
		if (response.status >= 200 && response.status < 300)
			dispatch(compoundLocationActions.setCompoundLocation(response.data));
		else WarningToast("An error has occur");
	};
};

export const AddCompoundLocation = (
	id,
	formData,
	navigate,
	setIsLoading,
	update = false
) => {
	return async (dispatch) => {
		try {
			setIsLoading({ loading: true, progress: 0 });

			let response;
			if (update) {
				response = await DataService.patch(`/compound-location/admin/${id}`, formData);
			} else {
				response = await DataService.post(`/compound-location/admin`, formData);
			}
			dispatch(compoundLocationActions.addCompoundLocation(response.data));
			SuccessToast("edited");
			setIsLoading({ loading: false, progress: 0 });
			navigate(-1);
		} catch (error) {
			setIsLoading({ loading: false, progress: 0 });
			WarningToast("An error has occurred");
			//console.log(error);
		}
	};
};

export const deleteCompoundLocation = (id) => {
	return async (dispatch) => {
		try {
			const response = await DataService.delete(`/compound-location/admin/${id}`);
			if (response.status >= 200 && response.status < 300) {
				dispatch(getCompoundLocation(pagination));
				SuccessToast("delete success");
			} else {
				WarningToast("An error has occur");
			}
		} catch (err) {
			WarningToast("An error has occur");
		}
	};
};