import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";

const initialState = {
  loading: false,
  error: null,
  users: [],
  staff: [],
  totalUsers: 0,
  totalStaff: 0,
};
const pagin = {
  sort: "-createdAt",
  page: 1,
  limit: 10,
};

const usersSlice = createSlice({
  name: "Users",
  initialState,
  reducers: {
    setUsers(state, action) {
      state.users = action.payload.users;
      state.totalUsers = action.payload.total;
    },
    setStaff(state, action) {
      state.staff = action.payload.staff;
      state.totalStaff = action.payload.total;
    },
    addStaff(state, action) {
      state.staff.unshift(action.payload);
    },
    editStaff(state, action) {
      const index = state.staff.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) state.staff.push(action.payload);
      else state.staff[index] = action.payload;
    },
  },
});

export const usersActions = usersSlice.actions;
export default usersSlice.reducer;

export const getUsers = (data) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `/user/all?${new URLSearchParams(data).toString()}`
      );
      if (response.status >= 200 && response.status < 300) {
        dispatch(usersActions.setUsers(response.data));
      }
    } catch (err) {
      WarningToast(err.response.data.message);
    }
  };
};
// edit user
export const editUser = (id, data, navigate) => {
  return async (dispatch) => {
    try {
      //console.log("data", data);
      const response = await DataService.patch(`/user/edit-data/${id}`, data);
      navigate(-1);
      SuccessToast("User updated successfully");
      dispatch(getUsers(pagin));
    } catch (error) {
      WarningToast(error.response.data.message, 20000);

      //console.log("error", error);
    }
  };
};

export const getStaff = (data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/staff/all?${new URLSearchParams(data).toString()}`
    );
    if (response.status >= 200 && response.status < 300) {
      dispatch(usersActions.setStaff(response.data));
    } else {
      WarningToast("An error has occur");
    }
  };
};

export const addNewStaff = (user, navigate) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(`/staff`, user);
      if (response.status >= 200 && response.status < 300) {
        dispatch(usersActions.addStaff(response.data));
        SuccessToast("User has been added");
        navigate("/apps/staff");
      } else WarningToast("An error has occur");
    } catch (e) {
      WarningToast(e.response.data.message, 10);
    }
  };
};

export const editStaff = (id, data, navigate) => {
  return async (dispatch) => {
    const response = await DataService.patch(`/staff/${id}`, data);
    //console.log(response);
    if (response.status >= 200 && response.status < 300) {
      dispatch(usersActions.editStaff(response.data));
      SuccessToast("edited");
      navigate(-1);
    } else {
      WarningToast("An error has occur");
    }
  };
};
export const deleteStaff = (id) => {
  return async (dispatch) => {
    const response = await DataService.delete(`/staff/${id}`);
    //console.log(response);
    if (response.status >= 200 && response.status < 300) {
      dispatch(getStaff(pagin));
      SuccessToast("Deleted");
    } else {
      WarningToast("An error has occur");
    }
  };
};
export const updateOtp = (id, code, navigate) => {
  return async (dispatch) => {
    try {
      const response = await DataService.patch(`/user/otp/${id}`, code);
      //console.log(response);
      dispatch(getUsers(pagin));
      SuccessToast("Updated");
    } catch (error) {
      WarningToast(`${error.response.data.message}`, 10);
      navigate(-1);
    }
  };
};
