// steller product slice
import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";
const initState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
};
export const stellerproductSlice = createSlice({
  name: "stellerproduct",
  initialState: initState,
  reducers: {
    setStellerProduct(state, action) {
      //console.log("action.payload", action.payload);
      state.data = action.payload.products;
      state.total = action.payload.total;
    },
    editStellerProduct(state, action) {
      const index = state.data.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) state.data.push(action.payload);
      else state.data[index] = action.payload;
    },
  },
});
export const stellerproductActions = stellerproductSlice.actions;
export default stellerproductSlice.reducer;
export const getAllStellerProduct = (query) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/stellar/product/all?${new URLSearchParams(query).toString()}`
    );
    dispatch(stellerproductActions.setStellerProduct(response.data));
  };
};
// add steller product
export const addStellerProduct = (
  id,
  { productImage },
  formData,
  navigate,
  setIsLoading,
  update = false
) => {
  //console.log("formData==>", formData);
  return async (dispatch) => {
    try {
      setIsLoading({ loading: true, progress: 0 });

      if (productImage) {
        const images = await uploadFileS3([...productImage], setIsLoading);
        formData.image = images[0];
      }

      let response;
      if (update) {
        response = await DataService.patch(`/stellar/product/${id}`, formData);
        //console.log("form=====>", formData);
      } else {
        response = await DataService.post(`/stellar/product`, formData);
      }
      dispatch(stellerproductActions.editStellerProduct(response.data));
      SuccessToast("edited");
      setIsLoading({ loading: false, progress: 0 });
      navigate(-1);
    } catch (error) {
      setIsLoading({ loading: false, progress: 0 });
      WarningToast("An error has occurrect");
      //console.log(error);
    }
  };
};
// delete steller product
export const deleteStellerProduct = (id) => {
  return async (dispatch) => {
    const response = await DataService.delete(`/stellar/deleteproduct/${id}`);
    if (response.status >= 200 && response.status < 300) {
      dispatch(getAllStellerProduct());
      SuccessToast("Product deleted successfully");
    }
  };
};
