import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { WarningToast } from "../../utility/toast";

const initialState = {
  loading: false,
  error: null,
  data: [],
  jobErrors: [],
  total: 0,
  totalJobErrors: 0,
};

const jobMonitorSlice = createSlice({
  name: "jobMonitor",
  initialState,
  reducers: {
    setJobMonitor(state, action) {
      state.data = action.payload.jobs;
      state.total = action.payload.total;
    },
    setJobErrors(state, action) {
      state.jobErrors = action.payload.jobsErrors;
      state.totalJobErrors = action.payload.total;
    },
  },
});

export const jobMonitorActions = jobMonitorSlice.actions;
export default jobMonitorSlice.reducer;

export const getJobMonitor = (data) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `/JobMonitor?${new URLSearchParams(data).toString()}`
      );
      //console.log("Data", response);
      dispatch(jobMonitorActions.setJobMonitor(response.data));
    } catch (err) {
      WarningToast("An error has occur");
      //console.log(err);
    }
  };
};

export const getJobErrors = (jobMonitor, data) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `/JobMonitor/errors/${jobMonitor}?${new URLSearchParams(
          data
        ).toString()}`
      );
      //console.log("Data", response);
      dispatch(jobMonitorActions.setJobErrors(response.data));
    } catch (err) {
      WarningToast("An error has occured");
      //console.log(err);
    }
  };
};
