import axios from "axios";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";
import { getStatistics } from "../statistics/statisticsSlice";


const { createSlice } = require("@reduxjs/toolkit");

const InitialState = {
	data: [],
	total: 0,
};

const pagination = {
	sort: "-createdAt",
	page: 1,
	limit: 10,
};

const careerSlice = createSlice({
	name: "career",
	initialState: InitialState,
	reducers: {
		setCareer(state, action) {
			state.data = action.payload.career;
			state.total = action.payload.total;
			// //console.log("payload", action.payload.total);
		},
		addCareer(state, action) {
			state.data.unshift(action.payload);
		},
		editCareer(state, action) {
			const index = state.data.findIndex(
				(item) => item._id === action.payload._id
			);
			if (index === -1) state.data.push(action.payload);
			else state.data[index] = action.payload;
		},
	},
});


export const careerActions = careerSlice.actions;
export default careerSlice.reducer;

export const getCareer = (data) => {
	return async (dispatch) => {
		const response = await DataService.get(
			`/career/admin/all?${new URLSearchParams(data).toString()}`
		);
		//console.log("response", response);
		if (response.status >= 200 && response.status < 300)
			dispatch(careerActions.setCareer(response.data));
		else WarningToast("An error has occur");
	};
};

export const EditCareer = (
	id,
	formData,
	navigate,
	setIsLoading,
) => {
	return async (dispatch) => {
		try {
			setIsLoading({ loading: true, progress: 0 });
			// let response;
			await DataService.patch(`/career/admin/${id}`, formData);

			SuccessToast("edited");
			setIsLoading({ loading: false, progress: 0 });
			dispatch(getStatistics());
			navigate(-1);
		} catch (error) {
			setIsLoading({ loading: false, progress: 0 });
			WarningToast("An error has occurred");
			//console.log(error);
		}
	};
};

// export const deleteRide = (id) => {
// 	return async (dispatch) => {
// 		try {
// 			const response = await DataService.delete(`/rides/admin/${id}`);
// 			if (response.status >= 200 && response.status < 300) {
// 				dispatch(getRides(pagination));
// 				SuccessToast("delete success");
// 			} else {
// 				WarningToast("An error has occur");
// 			}
// 		} catch (err) {
// 			WarningToast("An error has occur");
// 		}
// 	};
// };