import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { getStatistics } from "../statistics/statisticsSlice";

const initState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
  unRead: 0,
};

const complaintSlice = createSlice({
  name: "complaint",
  initialState: initState,
  reducers: {
    setComplaints(state, action) {
      state.data = action.payload.complaints;
      state.total = action.payload.total;
    },
    editComplaint(state, action) {
      const index = state.data.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) state.data.push(action.payload);
      else state.data[index] = action.payload;
    },
    setUnRead(state, action) {
      state.unRead = action.payload;
    },
  },
});

export const complaintActions = complaintSlice.actions;
export default complaintSlice.reducer;

export const getNewComplaints = (data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/complaint/all?${new URLSearchParams(data).toString()}`
    );
    //console.log("complaints=====>", response.data.complaints);
    dispatch(complaintActions.setComplaints(response.data));
  };
};

export const editNewComplaints = (id, data, navigate) => {
  return async (dispatch) => {
    //console.log("editNewComplaints", data);
    const response = await DataService.patch(`/complaint/${id}`, data);
    dispatch(getStatistics());
    if (response.status >= 200 && response.status < 300) {
      dispatch(complaintActions.editComplaint(response.data));
      SuccessToast("edited");
      navigate(-1);
    } else {
      WarningToast("An error has occur");
    }
  };
};
