import axios from "axios";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";


const { createSlice } = require("@reduxjs/toolkit");

const InitialState = {
	data: [],
	total: 0,
	teamData: {},
};

const pagination = {
	sort: "-createdAt",
	page: 1,
	limit: 10,
};

const teamsSlice = createSlice({
	name: "teams",
	initialState: InitialState,
	reducers: {
		setTeams(state, action) {
			state.data = action.payload.teams;
			state.total = action.payload.total;
			//console.log("payload", action.payload.total);
		},
		setTeamMemberDate(state, action) {
			state.teamData = action.payload;
			// state.pageId = action.payload._id;
		},
		addTeams(state, action) {
			state.data.unshift(action.payload);
		},
		editTeams(state, action) {
			const index = state.data.findIndex(
				(item) => item._id === action.payload._id
			);
			if (index === -1) state.data.push(action.payload);
			else state.data[index] = action.payload;
		},
	},
});


export const teamsActions = teamsSlice.actions;
export default teamsSlice.reducer;

export const getTeams = (data, id) => {
	return async (dispatch) => {
		const response = await DataService.get(
			`/team/admin/club/${id}?${new URLSearchParams(data).toString()}`
		);
		//console.log("response", response);
		if (response.status >= 200 && response.status < 300)
			dispatch(teamsActions.setTeams(response.data));
		else WarningToast("An error has occur");
	};
};

export const AddTeam = (
	id,
	formData,
	navigate,
	setIsLoading,
	update = false
) => {
	return async (dispatch) => {
		try {
			setIsLoading({ loading: true, progress: 0 });
			if (formData.files.imgFile) {
				const imgFile = await uploadFileS3([...formData.files.imgFile], setIsLoading);
				formData.image = imgFile[0];
			}
			delete formData.files;

			let response;
			//console.log("formData", formData);
			if (update) {
				response = await DataService.patch(`/team/admin/${id}`, formData);
			} else {
				response = await DataService.post(`/team/admin`, formData);
			}

			dispatch(getTeams(pagination, formData.compound));
			SuccessToast("edited");
			setIsLoading({ loading: false, progress: 0 });
			navigate(-1);
		} catch (error) {
			setIsLoading({ loading: false, progress: 0 });
			WarningToast("An error has occurred");
			//console.log(error);
		}
	};
};

export const deleteTeam = (id, compound) => {
	return async (dispatch) => {
		try {
			const response = await DataService.delete(`/team/admin/${id}`);
			if (response.status >= 200 && response.status < 300) {
				dispatch(getTeams(pagination, compound));
				SuccessToast("delete success");
			} else {
				WarningToast("An error has occur");
			}
		} catch (err) {
			WarningToast("An error has occur");
		}
	};
};

export const GetTeamMembersData = (id) => {
	return async (dispatch) => {
		try {
			const response = await DataService.get(`/team/admin/${id}/members`);
			if (response.status >= 200 && response.status < 300) {
				dispatch(teamsActions.setTeamMemberDate(response.data));
			} else {
				WarningToast("An error has occur");
			}
		} catch (err) {
			WarningToast("An error has occur");
		}
	};
}

export const AddTeamMember = (id, formData, navigate, setIsLoading, update = false) => {
	return async (dispatch) => {
		try {
			setIsLoading({ loading: true, progress: 0 });
			if (formData.files.imgFile) {
				const imgFile = await uploadFileS3([...formData.files.imgFile], setIsLoading);
				formData.photo = imgFile[0];
				//console.log("imgFile", imgFile[0]);
			}
			delete formData.files;

			let response;
			//console.log("formData", formData);
			if (!update) {
				response = await DataService.post(`/team/admin/${id}/member`, formData);
			} else {
				id = formData.teamId;
				response = await DataService.patch(`/team/admin/member/${formData._id}`, formData);
			}

			dispatch(GetTeamMembersData(id));
			SuccessToast("edited");
			setIsLoading({ loading: false, progress: 0 });
			navigate(-1);
		} catch (error) {
			setIsLoading({ loading: false, progress: 0 });
			WarningToast("An error has occurred");
			//console.log(error);
		}
	};


}

export const deleteTeamMember = (memberId, teamId) => {
	return async (dispatch) => {
		try {
			const response = await DataService.delete(`/team/admin/member/${memberId}`);
			if (response.status >= 200 && response.status < 300) {
				dispatch(GetTeamMembersData(teamId));
				SuccessToast("delete success");
			} else {
				WarningToast("An error has occur");
			}
		} catch (err) {
			WarningToast("An error has occur");
		}
	};
}