import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";

const initialState = {
  loading: false,
  error: null,
  data: [],
};

const serviceSlice = createSlice({
  name: "Services",
  initialState,
  reducers: {
    setServices(state, action) {
      //console.log(action.payload);
      state.data = action.payload;
    },
    editServices(state, action) {
      const index = state.data.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) state.data.push(action.payload);
      else state.data[index] = action.payload;
    },
  },
});

export const servicesActions = serviceSlice.actions;
export default serviceSlice.reducer;

export const getServices = (data) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `/services/admin/all?${new URLSearchParams(data).toString()}`
      );
      if (response.status >= 200 && response.status < 300) {
        dispatch(servicesActions.setServices(response.data));
        // SuccessToast("")
      } else {
        WarningToast("An error has occur");
      }
    } catch (e) {
      WarningToast("An error has occur", e);
    }
  };
};

export const addNewService = (service, navigator) => {
  return async (dispatch) => {
    const response = await DataService.post(`/services`, service);
    if (response.status >= 200 && response.status < 300) {
      dispatch(servicesActions.editServices(response.data));
      dispatch(getServices());
      SuccessToast("Service has been added");
      navigator("/apps/services");
    } else WarningToast("An error has occur");
  };
};

export const editServices = (id, data, navigator) => {
  return async (dispatch) => {
    try {
      const response = await DataService.patch(`/services/${id}`, data);
      if (response.status >= 200 && response.status < 300) {
        dispatch(servicesActions.editServices(response.data));
        SuccessToast("edited");
        navigator("/apps/services");
      } else {
        WarningToast("An error has occur");
      }
    } catch (e) {
      WarningToast("An error has occur");
    }
  };
};
export const AddService = (
  id,
  formData,
  navigate,
  setIsLoading,
  update = false
) => {
  return async (dispatch) => {
    //console.log("formData out", formData);
    try {
      setIsLoading({ loading: true, progress: 0 });
      if (formData.files.iconImage) {
        //console.log("formData.files.iconImage", [...formData.files.iconImage]);
        const iconImage = await uploadFileS3([...formData.files.iconImage], setIsLoading);
        formData.icon = iconImage[0];
      }

      // subServices urls assign image
      for (let i = 0; i < formData.subServices.length; i++) {
				if (formData.subServices[i]?.url?.images) {
					// //console.log("formData.images[i].images", [formData.images[i].images]);
					const images = await uploadFileS3([formData.subServices[i].url.images], setIsLoading);
					formData.subServices[i].url = images[0];
				}
			}
      delete formData.files;
      //console.log("formData", formData);
      let response;
      //console.log("formData", formData);
      if (update) {
        response = await DataService.patch(`/services/${id}`, formData);
      } else {
        response =  await DataService.post(`/services`, formData);
      }
      dispatch(servicesActions.editServices(response.data));
      dispatch(getServices());
      SuccessToast("edited");
      setIsLoading({ loading: false, progress: 0 });
      navigate(-1);
    } catch (error) {
      setIsLoading({ loading: false, progress: 0 });
      WarningToast("An error has occurred");
      //console.log(error);
    }
  };
};
// delet service
export const deleteService = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(`/services/serv/${id}`);
      if (response.status >= 200 && response.status < 300) {
        dispatch(getServices());
        SuccessToast("Service has been deleted");
      } else {
        WarningToast("An error has occur");
      }
    } catch (e) {
      WarningToast("An error has occur");
    }
  };
};
