import axios from "axios";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";


const { createSlice } = require("@reduxjs/toolkit");

const InitialState = {
  data: [],
  total: 0,
};

const pagination = {
  sort: "-createdAt",
  page: 1,
  limit: 10,
};

const newEventsSlice = createSlice({
  name: "newEvents",
  initialState: InitialState,
  reducers: {
    setNewEvents(state, action) {
      state.data = action.payload.newEvents;
      state.total = action.payload.total;
    },
    addNewEvent(state, action) {
      state.data.unshift(action.payload);
    },
    editNewEvent(state, action) {
      const index = state.data.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) state.data.push(action.payload);
      else state.data[index] = action.payload;
    },
  },
});


export const eventTypesActions = newEventsSlice.actions;
export default newEventsSlice.reducer;

export const getEventTypes = (data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/new-event/admin/all?${new URLSearchParams(data).toString()}`
    );
    if (response.status >= 200 && response.status < 300)
      dispatch(eventTypesActions.setNewEvents(response.data));
    else WarningToast("An error has occur");
  };
};

export const AddEventType = (
  id,
  formData,
  navigate,
  setIsLoading,
  update = false
) => {
  return async (dispatch) => {
    try {
      setIsLoading({ loading: true, progress: 0 });

      if (formData.files.webPc) {
        const webPc = await uploadFileS3([...formData.files.webPc], setIsLoading);
        formData.coverImages.webPc = webPc[0];
      }
      //console.log("formData.cardImg", formData.cardImage)
      if (formData.cardImage) {
        //console.log("formData.cardImg", formData.cardImage)
        if (typeof formData.cardImage !== "string" || !formData.cardImage.includes('http')) {
          //console.log("formData.cardImg not string", formData.cardImage)
          const cardImage = await uploadFileS3(
            [...formData.cardImage],
            setIsLoading
          );
          formData.cardImage = cardImage[0];
        }
      }
      if (formData.files.webMobile) {
        const webMobile = await uploadFileS3(
          [...formData.files.webMobile],
          setIsLoading
        );
        formData.coverImages.webMobile = webMobile[0];
      }
      if (formData.files.app) {
        const app = await uploadFileS3([...formData.files.app], setIsLoading);
        formData.coverImages.app = app[0];
      }
      if (formData?.files?.videoPrev) {
        const images = await uploadFileS3(
          [formData.files.videoPrev],
          setIsLoading
        );
        formData.videoUrl = images[0];
      }
      delete formData.files;
      if (formData.pastImages?.length > 0)
        for (let i = 0; i < formData.pastImages.length; i++) {
          //console.log("formData.images[i]", formData.pastImages[i]);
          if (formData.pastImages[i].images) {
            //console.log("formData.images[i].images", [formData.pastImages[i].images]);
            const images = await uploadFileS3([formData.pastImages[i].images], setIsLoading);
            formData.pastImages[i] = images[0];
          }

        }

      let response;
      if (update) {
        response = await DataService.patch(`/new-event/admin/${id}`, formData);
      } else {
        response = await DataService.post(`/new-event/admin`, formData);
      }
      dispatch(eventTypesActions.addNewEvent(response.data));
      SuccessToast("edited");
      setIsLoading({ loading: false, progress: 0 });
      navigate(-1);
    } catch (error) {
      setIsLoading({ loading: false, progress: 0 });
      WarningToast("An error has occurred");
      //console.log(error);
    }
  };
};

export const deleteNewEvent = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(`/new-event/admin/${id}`);
      if (response.status >= 200 && response.status < 300) {
        dispatch(getEventTypes(pagination));
        SuccessToast("delete success");
      } else {
        WarningToast("An error has occur");
      }
    } catch (err) {
      WarningToast("An error has occur");
    }
  };
};