import axios from "axios";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";

const { createSlice } = require("@reduxjs/toolkit");

const initalState = {
  data: [],
  total: 1,
};

const pagin = {
  sort: "-createdAt",
  page: 1,
  limit: 10,
};

const constructionsSlice = createSlice({
  name: "constructions",
  initialState: initalState,
  reducers: {
    setConstUpdates(state, action) {
      state.data = action.payload;
    },
    addConstUpdates(state, action) {
      state.data.unshift(action.payload);
    },
  },
});

export const constructionActions = constructionsSlice.actions;
export default constructionsSlice.reducer;

export const getConstUpdates = (id) => {
  return async (dispatch) => {
    const response = await DataService.get(`/constraction-update/admin/${id}`);
    if (response.status >= 200 && response.status < 300)
      dispatch(constructionActions.setConstUpdates(response.data));
    else WarningToast("An error has occur");
  };
};

export const AddConstUpdates = (
  id,
  { imagePrev, videoPrev, pdfsPrev, cardImg },
  formData,
  navigate,
  setIsLoading,
  update
) => {
  return async (dispatch) => {
    //console.log("🚀 ~ file: constructionsSlice.js:80 ~ cardImg", cardImg);
    //console.log(formData);
    try {
      setIsLoading({ loading: true, progress: 0 });
      if (imagePrev.length > 0)
        formData.images = [
          ...(formData.images || []),
          ...(await uploadFileS3(imagePrev, setIsLoading)),
        ];
      if (videoPrev)
        formData.videos = [
          ...(formData.videos || []),
          ...(await uploadFileS3([videoPrev], setIsLoading)),
        ];
      if (pdfsPrev)
        formData.pdfs = [
          ...(formData.pdfs || []),
          ...(await uploadFileS3([pdfsPrev], setIsLoading)),
        ];

      if (formData.pdfs?.pdf) {
        const pdfs = await uploadFileS3([formData.pdfs.pdf], setIsLoading);
        formData.pdfs = pdfs[0];
      }

      if (formData.fileImg.cardImg) {
        const cardImg = await uploadFileS3([...formData.fileImg.cardImg], setIsLoading);
        formData.cardImage = cardImg[0];
      }
      delete formData.fileImg;

      // if (cardImg) {
      //   const cardImg = await uploadFileS3([...cardImg], setIsLoading);
      //   formData.cardImage = cardImg[0];
      // }

      let response;
      if (update)
        response = await DataService.patch(
          `/constraction-update/${id}`,
          formData
        );
      else {
        formData.compound = id;
        response = await DataService.post(`/constraction-update`, formData);
      }
      dispatch(constructionActions.addConstUpdates(response.data));
      SuccessToast("edited");
      setIsLoading({ loading: false, progress: 0 });
      navigate(-1);
    } catch (error) {
      setIsLoading({ loading: false, progress: 0 });
      WarningToast("An error has occurrect");
      //console.log(error);
    }
  };
};

export const deleteConstUpdates = (id, compoundId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(`/constraction-update/${id}`);
      if (response.status >= 200 && response.status < 300) {
        dispatch(getConstUpdates(compoundId));
        SuccessToast("delete success");
      } else {
        WarningToast("An error has occur");
      }
    } catch (err) {
      WarningToast("An error has occur");
    }
  };
};
