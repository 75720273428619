import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { WarningToast } from "../../utility/toast";

const initialState = {
	data: [],
	jobErrors: [],
	total: 0,
	totalJobErrors: 0,
};

const NotifyMonitorSlice = createSlice({
	name: "notifyMonitor",
	initialState,
	reducers: {
		setNotifyMonitor(state, action) {
			state.data = action.payload.notifyMonitor;
			state.total = action.payload.total;
		},
		setJobErrors(state, action) {
			state.jobErrors = action.payload.jobsErrors;
			state.totalJobErrors = action.payload.total;
		},
	},
});

export const notifyMonitorActions = NotifyMonitorSlice.actions;
export default NotifyMonitorSlice.reducer;

export const getNotifyMonitor = (data) => {
	
	return async (dispatch) => {
		try {
			const response = await DataService.get(
				`/notification/monitor?${new URLSearchParams(data).toString()}`
			);
			//console.log("Data", response);
			dispatch(notifyMonitorActions.setNotifyMonitor(response.data));
		} catch (err) {
			WarningToast("An error has occur");
			//console.log(err);
		}
	};
};

export const getJobErrors = (notifyMonitor, data) => {
	return async (dispatch) => {
		try {
			const response = await DataService.get(
				`/JobMonitor/errors/${notifyMonitor}?${new URLSearchParams(
					data
				).toString()}`
			);
			//console.log("Data", response);
			dispatch(notifyMonitorActions.setJobErrors(response.data));
		} catch (err) {
			WarningToast("An error has occured");
			//console.log(err);
		}
	};
};
