import axios from "axios";
import { useSelector } from "react-redux";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";


const { createSlice } = require("@reduxjs/toolkit");

const InitialState = {
	data: [],
	total: 0,
	slidesDate: {},
	pageId: "",
};

const pagination = {
	sort: "-createdAt",
	page: 1,
	limit: 10,
};

const configPageSlice = createSlice({
	name: "configPage",
	initialState: InitialState,
	reducers: {
		setConfigPage(state, action) {
			state.data = action.payload.configPages;
			state.total = action.payload.total;
			// //console.log("payload", action.payload.total);
		},
		setSlidesDate(state, action) {
			state.slidesDate = action.payload;
			state.pageId = action.payload._id;
		},
		deleteOneFromSlidesData(state, action) {
			const index = state.slidesDate.slides.findIndex(
				(item) => item._id === action.payload
			);
			state.slidesDate.slides.splice(index, 1);
		},
		addRides(state, action) {
			state.data.unshift(action.payload);
		},
		editRides(state, action) {
			const index = state.data.findIndex(
				(item) => item._id === action.payload._id
			);
			if (index === -1) state.data.push(action.payload);
			else state.data[index] = action.payload;
		},
	},
});


export const configPageActions = configPageSlice.actions;
export default configPageSlice.reducer;

export const GetConfigPages = (data) => {
	return async (dispatch) => {
		try {
			const response = await DataService.get(
				`/config-page/admin/all?${new URLSearchParams(data).toString()}`
			);
			//console.log("response", response);
			if (response.status >= 200 && response.status < 300)
				dispatch(configPageActions.setConfigPage(response.data));
			else WarningToast("An error has occur");
		} catch (err) {
			WarningToast(err.response.data.message);
		}
	};
};

export const GetSlidesDate = (id) => {
	return async (dispatch) => {
		const response = await DataService.get(`/config-page/admin/${id}`);
		if (response.status >= 200 && response.status < 300)
			dispatch(configPageActions.setSlidesDate(response.data));
		else WarningToast("An error has occur");
	};
};

export const EditConfigSection = (id, formData, setIsLoading, navigate) => {
	return async (dispatch) => {
		//console.log("formData", formData);
		try {
			if (formData.section?.image?.images) {
				const images = await uploadFileS3([formData.section.image.images], setIsLoading);
				formData.section.image = images[0];
			}
			setIsLoading({ loading: true, progress: 0 });
			const response = await DataService.patch(`/config-page/edit-section/${id}`, formData);
			if (response.status >= 200 && response.status < 300) {
				SuccessToast("Section has been edited successfully");
				navigate(-1);
				// navigate("/apps/config-pages");
			} else WarningToast("An error has occur");
		} catch (err) {
			WarningToast(err.response.data.message);
		}
		setIsLoading({ loading: false, progress: 0 });
	};
};

export const AddSlides = (id, formData, navigate, setIsLoading, parent) => {
	return async (dispatch) => {
		try {
			setIsLoading({ loading: true, progress: 0 });

			if (formData?.files?.imgFile) {
				const slideImage = await uploadFileS3([...formData.files.imgFile], setIsLoading);
				formData.img = slideImage[0];
			}
			if (formData?.files?.videoPrev) {
				const video = await uploadFileS3([formData.files.videoPrev], setIsLoading);
				//console.log("video ==>", video[0])
				formData.video = video[0];
			}
			delete formData.files;
			let response;
			if (parent) {
				response = await DataService.patch(`/config-page/edit-slide/${id}`, formData);
			} else {
				response = await DataService.post(`/config-page/add-new-slide/${id}`, formData);
			}
			if (response.status >= 200 && response.status < 300) {
				dispatch(GetSlidesDate(id));
				SuccessToast("added");
				setIsLoading({ loading: false, progress: 0 });
				navigate(-1);
			} else {
				setIsLoading({ loading: false, progress: 0 });
				WarningToast("An error has occur");
			}
		} catch (err) {
			setIsLoading({ loading: false, progress: 0 });
			WarningToast(err.response.data.message);
		}
	};
}

export const DeleteSlide = (slideId) => {
	// get page id from store
	return async (dispatch) => {
		try {
			const response = await DataService.delete(`/config-page/delete-slide/${slideId}`);
			if (response.status >= 200 && response.status < 300) {
				SuccessToast("deleted");
				dispatch(configPageActions.deleteOneFromSlidesData(slideId));
			} else WarningToast("An error has occur");
		} catch (err) {
			WarningToast(err.response.data.message);
		}
	};
}
