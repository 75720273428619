import axios from "axios";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";


const { createSlice } = require("@reduxjs/toolkit");

const initalState = {
  data: [],
  total: 0,
};

const pagin = {
  sort: "-createdAt",
  page: 1,
  limit: 10,
};

const phasesSlice = createSlice({
  name: "phases",
  initialState: initalState,
  reducers: {
    setPhases(state, action) {
      state.data = action.payload.phases;
      state.total = action.payload.total;
      //console.log("payload", action.payload.total);
    },
    addPhases(state, action) {
      state.data.unshift(action.payload);
    },
  },
});

export const phasesActions = phasesSlice.actions;
export default phasesSlice.reducer;

export const getPhases = (compoundId, data) => {
  return async (dispatch) => {
    //console.log("compoundId", compoundId);
    const response = await DataService.get(
      `/phases/admin/all/${compoundId}?${new URLSearchParams(data).toString()}`
    );
    //console.log("response", response);
    if (response.status >= 200 && response.status < 300)
      dispatch(phasesActions.setPhases(response.data));
    else WarningToast("An error has occur");
  };
};

export const AddPhases = (
  id,
  formData,
  navigate,
  setIsLoading,
  update = false
) => {
  return async (dispatch) => {
    //console.log("data====>", formData);
    try {
      setIsLoading({ loading: true, progress: 0 });
      if (formData.files.logoImg) {
        const logoImg = await uploadFileS3([...formData.files.logoImg], setIsLoading);
        formData.logo = logoImg[0];
      }
      if (formData.files.cardImage) {
        const cardImage = await uploadFileS3([...formData.files.cardImage], setIsLoading);
        formData.cardImage = cardImage[0];
      }
      if (formData.masterPlan?.image?.images) {
        const images = await uploadFileS3([formData.masterPlan.image.images], setIsLoading);
        formData.masterPlan.image = images[0];
      }
      if (formData.brochure?.pdf) {
        const brochure = await uploadFileS3([formData.brochure.pdf], setIsLoading);
        formData.brochure = brochure[0];
      }
      if (formData.video?.video) {
        const videoUploaded = await uploadFileS3([formData.video.video], setIsLoading);
        formData.video = videoUploaded[0];
      }
      for (let i = 0; i < formData.amenities.length; i++) {
        if (formData.amenities[i]?.image?.images) {
          // //console.log("formData.images[i].images", [formData.images[i].images]);
          const images = await uploadFileS3([formData.amenities[i].image.images], setIsLoading);
          formData.amenities[i].image = images[0];
        }
      }
      for (let i = 0; i < formData.images.length; i++) {
        if (formData.images[i].images) {
          //console.log("formData.images[i].images", [formData.images[i].images]);
          const images = await uploadFileS3([formData.images[i].images], setIsLoading);
          formData.images[i] = images[0];
        }
      }


      let response;
      if (update) {
        response = await DataService.patch(`/phases/${id}`, formData);
      } else {
        response = await DataService.post(`/phases`, formData);
      }

      dispatch(phasesActions.addPhases(response.data));
      SuccessToast("edited");
      setIsLoading({ loading: false, progress: 0 });
      navigate(`/apps/phases/${formData.compound}`);
    } catch (error) {
      setIsLoading({ loading: false, progress: 0 });
      WarningToast("An error has occurred");
      //console.log(error);
    }
  };
};

export const deletePhase = (id, compoundId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(`/phases/${id}`);
      if (response.status >= 200 && response.status < 300) {
        dispatch(getPhases(compoundId));
        SuccessToast("delete success");
      } else {
        WarningToast("An error has occur");
      }
    } catch (err) {
      WarningToast("An error has occur");
    }
  };
};
