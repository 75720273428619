import axios from "axios";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";


const { createSlice } = require("@reduxjs/toolkit");

const InitialState = {
	data: [],
	total: 0,
};

const pagination = {
	sort: "-createdAt",
	page: 1,
	limit: 10,
};

const ridesSlice = createSlice({
	name: "rides",
	initialState: InitialState,
	reducers: {
		setRides(state, action) {
			state.data = action.payload.rides;
			state.total = action.payload.total;
			//console.log("payload", action.payload.total);
		},
		addRides(state, action) {
			state.data.unshift(action.payload);
		},
		editRides(state, action) {
			const index = state.data.findIndex(
				(item) => item._id === action.payload._id
			);
			if (index === -1) state.data.push(action.payload);
			else state.data[index] = action.payload;
		},
	},
});


export const ridesActions = ridesSlice.actions;
export default ridesSlice.reducer;

export const getRides = (data) => {
	return async (dispatch) => {
		const response = await DataService.get(
			`/rides/admin/all?${new URLSearchParams(data).toString()}`
		);
		//console.log("response", response);
		if (response.status >= 200 && response.status < 300)
			dispatch(ridesActions.setRides(response.data));
		else WarningToast("An error has occur");
	};
};

export const AddRide = (
	id,
	formData,
	navigate,
	setIsLoading,
	update = false
) => {
	return async (dispatch) => {
		try {
			setIsLoading({ loading: true, progress: 0 });
			if (formData.files.rideImage) {
				const rideImage = await uploadFileS3([...formData.files.rideImage], setIsLoading);
				formData.rideImage = rideImage[0];
			}
			let response;
			//console.log("formData", formData);
			if (update) {
				response = await DataService.patch(`/rides/admin/${id}`, formData);
			} else {
				response = await DataService.post(`/rides/admin`, formData);
			}
			dispatch(ridesActions.addRides(response.data));
			SuccessToast("edited");
			setIsLoading({ loading: false, progress: 0 });
			navigate(-1);
		} catch (error) {
			setIsLoading({ loading: false, progress: 0 });
			WarningToast("An error has occurred");
			//console.log(error);
		}
	};
};

export const deleteRide = (id) => {
	return async (dispatch) => {
		try {
			const response = await DataService.delete(`/rides/admin/${id}`);
			if (response.status >= 200 && response.status < 300) {
				dispatch(getRides(pagination));
				SuccessToast("delete success");
			} else {
				WarningToast("An error has occur");
			}
		} catch (err) {
			WarningToast("An error has occur");
		}
	};
};