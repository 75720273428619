import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";

const initialState = {
  loading: false,
  error: null,
  data: [],
  orders: [],
  totalOrders: [],
  total: 0,
  unRead: 0,
  selectedBill: null,
  billTypeData: [],
  billTypeTotal: 0,
  selectedBillType: null,
};

const billSlice = createSlice({
  name: "Bills",
  initialState,
  reducers: {
    setBills(state, action) {
      state.data = action.payload.bills;
      state.total = action.payload.total;
    },
    addBill(state, action) {
      state.data.unshift(action.payload);
    },
    setBillType(state, action) {
      state.billTypeData = action.payload.billTypes;
      state.billTypeTotal = action.payload.total;
    },
    addBillType(state, action) {
      state.billTypeData.unshift(action.payload);
    },
    setOrders(state, action) {
      state.orders = action.payload.billOrders;
      state.totalOrders = action.payload.total;
    },
    setSelectedBill(state, action) {
      state.selectedBill = action.payload;
    },
    selectedBillTypeTypes(state, action) {
      state.selectedBillType = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;

    },
  },
});

export const billActions = billSlice.actions;
export default billSlice.reducer;

export const getNewBills = (data) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `/bill?${new URLSearchParams(data).toString()}`
      );
      if (response.status >= 200 && response.status < 300) {
        dispatch(billActions.setBills(response.data));
      }
    } catch (e) {
      WarningToast(e.response.data.message);
    }
  };
};
export const billSubmission = (
  id,
  formData,
  navigate,
  setIsLoading,
  update = false
) => {
  return async (dispatch) => {
    try {
      setIsLoading({ loading: true, progress: 0 });
      if (formData.attachment?.file) {
        const attachment = await uploadFileS3([formData.attachment.file], setIsLoading);
        formData.attachment = attachment[0];
      }
      let response;
      if (update) {
        response = await DataService.patch(`/bill/${id}`, formData);
      } else {
        // response = await DataService.post(`/bill/bill-type`, formData);
      }
      dispatch(billActions.addBill(response.data));
      SuccessToast("edited");
      setIsLoading({ loading: false, progress: 0 });
      navigate(-1);
    } catch (error) {
      setIsLoading({ loading: false, progress: 0 });
      WarningToast(error.response.data.message);
      //console.log(error);
    }
  };
};

export const getOneBill = (id) => {
  return async (dispatch) => {
    try {
      dispatch(billActions.setLoading(true))

      const response = await DataService.get(
        `/bill/${id}`
      );
      if (response.status >= 200 && response.status < 300) {
        dispatch(billActions.setSelectedBill(response.data));
      }
      dispatch(billActions.setLoading(false))

    } catch (e) {
      dispatch(billActions.setLoading(false))

      WarningToast(e.response.data.message);
    }
  };
};

export const deleteBill = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(
        `/bill/${id}`
      );
      if (response.status >= 200 && response.status < 300) {
        dispatch(getNewBills());
      }
    } catch (e) {
      WarningToast(e.response.data.message);
    }
  };
};

export const getBillOrders = (data) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `/bill/bill-order?${new URLSearchParams(data).toString()}`
      );
      if (response.status >= 200 && response.status < 300) {
        dispatch(billActions.setOrders(response.data));
      }
    } catch (e) {
      WarningToast(e.response.data.message);
    }
  };
}
// edit checks

export const getBillTypes = (data) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `/bill/bill-type/all?${new URLSearchParams(data).toString()}`
      );
      if (response.status >= 200 && response.status < 300) {
        dispatch(billActions.setBillType(response.data));
      }
    } catch (e) {
      WarningToast(e.response.data.message);
    }
  };
};

export const getOneBillType = (id) => {
  return async (dispatch) => {
    try {
      dispatch(billActions.setLoading(true))

      const response = await DataService.get(
        `/bill/bill-type/${id}`
      );
      if (response.status >= 200 && response.status < 300) {
        dispatch(billActions.selectedBillTypeTypes(response.data));
      }
      dispatch(billActions.setLoading(false))

    } catch (e) {
      dispatch(billActions.setLoading(false))

      WarningToast(e.response.data.message);
    }
  };
};

export const billTypeSubmission = (
  id,
  formData,
  navigate,
  setIsLoading,
  update = false
) => {
  return async (dispatch) => {
    try {
      setIsLoading({ loading: true, progress: 0 });
      let response;
      if (update) {
        response = await DataService.patch(`/bill/bill-type/${id}`, formData);
      } else {
        response = await DataService.post(`/bill/bill-type`, formData);
      }
      dispatch(billActions.addBillType(response.data));
      SuccessToast("edited");
      setIsLoading({ loading: false, progress: 0 });
      navigate(-1);
    } catch (error) {
      setIsLoading({ loading: false, progress: 0 });
      WarningToast(error.response.data.message);
      //console.log(error);
    }
  };
};

// delete Bill Type
export const deleteBillType = (id, navigate) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(`/bill/bill-type/${id}`);
      if (response.status >= 200 && response.status < 300) {
        dispatch(getBillTypes());
        SuccessToast("deleted");
      } else {
        WarningToast("An error has occur");
      }
    } catch (e) {
      WarningToast("An error has occur", e);
    }
  };
};

// export const editCheck = (id, data, navigate) => {
//   return async (dispatch) => {
//     try {
//       const response = await DataService.patch(`/check/confirm/${id}`, data);
//       if (response.status >= 200 && response.status < 300) {
//         dispatch(getNewChecks());
//         SuccessToast("edited");
//         navigate(-1);
//       } else {
//         WarningToast("An error has occur");
//       }
//     } catch (e) {
//       WarningToast("An error has occur", e);
//     }
//   };
// };
