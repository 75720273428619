import axios from "axios";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";


const { createSlice } = require("@reduxjs/toolkit");

const InitialState = {
	data: [],
	total: 0,
};

const pagination = {
	sort: "-createdAt",
	page: 1,
	limit: 10,
};

const markerSlice = createSlice({
	name: "marker",
	initialState: InitialState,
	reducers: {
		setMarker(state, action) {
			state.data = action.payload.marker;
			state.total = action.payload.total;
		},
		addMarker(state, action) {
			state.data.unshift(action.payload);
		},
		editEventType(state, action) {
			const index = state.data.findIndex(
				(item) => item._id === action.payload._id
			);
			if (index === -1) state.data.push(action.payload);
			else state.data[index] = action.payload;
		},
	},
});


export const markerActions = markerSlice.actions;
export default markerSlice.reducer;

export const getMarker = (data) => {
	return async (dispatch) => {
		const response = await DataService.get(
			`/marker/admin/all?${new URLSearchParams(data).toString()}`
		);
		if (response.status >= 200 && response.status < 300)
			dispatch(markerActions.setMarker(response.data));
		else WarningToast("An error has occur");
	};
};

export const AddMarker = (
	id,
	formData,
	navigate,
	setIsLoading,
	update = false
) => {
	return async (dispatch) => {
		try {
			setIsLoading({ loading: true, progress: 0 });
			if (formData.image?.images) {
				const images = await uploadFileS3([formData.image.images], setIsLoading);
				formData.image = images[0];
			}

			let response;
			if (update) {
				response = await DataService.patch(`/marker/admin/${id}`, formData);
			} else {
				response = await DataService.post(`/marker/admin`, formData);
			}
			dispatch(markerActions.addMarker(response.data));
			SuccessToast("edited");
			setIsLoading({ loading: false, progress: 0 });
			navigate(-1);
		} catch (error) {
			setIsLoading({ loading: false, progress: 0 });
			WarningToast("An error has occurred");
			//console.log(error);
		}
	};
};

export const deleteMarker = (id) => {
	return async (dispatch) => {
		try {
			const response = await DataService.delete(`/marker/admin/${id}`);
			if (response.status >= 200 && response.status < 300) {
				dispatch(getMarker(pagination));
				SuccessToast("delete success");
			} else {
				WarningToast("An error has occur");
			}
		} catch (err) {
			WarningToast("An error has occur");
		}
	};
};