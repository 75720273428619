import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";

const initialState = {
	loading: false,
	error: null,
	data: [],
	total: 0,
};
const pagin = {
	sort: "-createdAt",
	page: 1,
	limit: 10,
};

const parkAccessSlice = createSlice({
	name: "parkAccess",
	initialState,
	reducers: {
		setParkAccess(state, action) {
			state.data = action.payload.parkAccess;
			state.total = action.payload.total;
		},
		setLoading(state, action) {
			state.loading = action.payload;
		},
	},
});

export const parkAccessActions = parkAccessSlice.actions;
export default parkAccessSlice.reducer;

export const getParkAccess = (data) => {
	return async (dispatch) => {
		try {
			const response = await DataService.get(
				`/park-access/admin/all?${new URLSearchParams(data).toString()}`
			);
			if (response.status >= 200 && response.status < 300) {
				dispatch(parkAccessActions.setParkAccess(response.data));
			}
		} catch (e) {
			console.log(e);
			WarningToast(e?.response?.data?.message);
		}
	};
};