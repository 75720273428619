import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";

const initState = {
  loading: false,
  error: null,
  data: [],
  userUnit: [],
  total: 0,
};

const pagin = {
  sort: "-createdAt",
  page: 1,
  limit: 10,
};

const uniteSlice = createSlice({
  name: "Unite",
  initialState: initState,
  reducers: {
    setUnites(state, action) {
      state.data = action.payload.unites;
      state.total = action.payload.total;
    },
    editUnite(state, action) {
      const index = state.data.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) state.data.push(action.payload);
      else state.data[index] = action.payload;
    },
    setUserUnit(state, action) {
      state.userUnit = action.payload.units;
    },
  },
});

export const uniteActions = uniteSlice.actions;
export default uniteSlice.reducer;

export const assignUnit = (data, setIsLoading, navigate) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      const response = await DataService.patch("/unit/assignunit", data);
      SuccessToast("Unit assigned successfully");
      navigate(-1);
      setIsLoading(false);
    } catch (err) {
      WarningToast(err.response.data.message, 20000);
      setIsLoading(false);
    }
  };
};

export const getAllUnites = (query) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `/unit/all?${new URLSearchParams(query).toString()}`
      );
      dispatch(uniteActions.setUnites(response.data));
    } catch (err) {
      WarningToast(err.response.data.message);
    }
  };
};
// get unit bu userId
export const getUnitByUserId = (userId) => {
  return async (dispatch) => {
    const response = await DataService.get(`/unit/user-units/${userId}`);
    //console.log("response==>", response.data);
    dispatch(uniteActions.setUserUnit(response.data));
  };
};
export const editUnitDetailes = (id, data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await DataService.patch(
        `/unit/unit-details/${id}`,
        data
      );
      navigate(-1);
      SuccessToast("Edited");
    } catch (err) {
      WarningToast(err.response.data.message);
    }
  };
};

export const editUnite = (
  id,
  formData,
  navigate,
  setIsLoading,
  update = false
) => {
  return async (dispatch) => {
    for (let i = 0; i < formData.images.length; i++) {
      if (formData.images[i].images) {
        const images = await uploadFileS3(
          [formData.images[i].images],
          setIsLoading
        );
        formData.images[i] = images[0];
      }
    }
    if (formData.techPdf?.pdf) {
      const techPdf = await uploadFileS3([formData.techPdf.pdf], setIsLoading);
      formData.techPdf = techPdf[0];
    }

    const response = await DataService.post(`/unit/${id}`, formData);
    if (response.status >= 200 && response.status < 300) {
      dispatch(uniteActions.editUnite(response.data));
      SuccessToast("edited");
      setIsLoading({ loading: false, progress: 0 });
      // navigate(-1);
    } else {
      WarningToast("An error has occurred");
    }
  };
};
