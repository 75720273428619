import axios from "axios";
import { DataService } from "../configs/dataService/dataService";

export const uploadFileS3 = async (files, setIsLoading) => {
  try {
    const uploadPromis = files.map((item) =>
      DataService.get(
        `/upload?contentType=${item?.type}&filename=${item?.name}`
      )
    );
    const uploadurls = await Promise.all(uploadPromis);
    const filesPromise = uploadurls.map(({ data }, i) =>
      axios.put(data, files[i], {
        headers: {
          "Content-Type": files[i].type,
        },
        onUploadProgress: (e) => {
          setIsLoading({
            loading: true,
            progress: Math.trunc((e.loaded / e.total) * 100),
          });
        },
      })
    );

    await Promise.all(filesPromise);
    return uploadurls.map(({ data }) => {
      const url = data.split("?")[0].replace("https://vqesportsplatform.s3.eu-west-3.amazonaws.com/", "https://d2e34kt1c5hzm6.cloudfront.net/")
      // replace 
      return url;
    });
  } catch (error) {
    throw error;
  }
};
