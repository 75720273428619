import { createSlice } from '@reduxjs/toolkit'

const popupImgSlice = createSlice({
	name: 'popupImg',
	initialState: {
		popupImgSrc: '',
		popupImgOpen: false,
	},
	reducers: {
		setPopupImgSrc(state, action) {
			state.popupImgSrc = action.payload
		},
		setPopupImgOpen(state, action) {
			state.popupImgOpen = action.payload
			if (!action.payload) {
				state.popupImgSrc = ''
			}
		},
		popupImg(state, action) {
			state.popupImgSrc = action.payload.popupImgSrc;
			state.popupImgOpen = true;
		}
	}
})
export const { popupImg, setPopupImgSrc, setPopupImgOpen } = popupImgSlice.actions
export default popupImgSlice.reducer