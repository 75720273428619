import axios from "axios";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";


const { createSlice } = require("@reduxjs/toolkit");

const InitialState = {
	data: [],
	total: 0,
};

const pagination = {
	sort: "-createdAt",
	page: 1,
	limit: 10,
};

const newsletterTypesSlice = createSlice({
	name: "newsletterTypes",
	initialState: InitialState,
	reducers: {
		setNewsletterTypes(state, action) {
			state.data = action.payload.newsletterTypes;
			state.total = action.payload.total;
		},
		addNewsletterType(state, action) {
			state.data.unshift(action.payload);
		},
		editNewsletterType(state, action) {
			const index = state.data.findIndex(
				(item) => item._id === action.payload._id
			);
			if (index === -1) state.data.push(action.payload);
			else state.data[index] = action.payload;
		},
	},
});


export const newsletterTypesActions = newsletterTypesSlice.actions;
export default newsletterTypesSlice.reducer;

export const getNewsletterTypes = (data) => {
	return async (dispatch) => {
		const response = await DataService.get(
			`/newsletter-type/admin/all?${new URLSearchParams(data).toString()}`
		);
		if (response.status >= 200 && response.status < 300)
			dispatch(newsletterTypesActions.setNewsletterTypes(response.data));
		else WarningToast("An error has occur");
	};
};

export const AddNewsletterType = (
	id,
	formData,
	navigate,
	setIsLoading,
	update = false
) => {
	return async (dispatch) => {
		try {
			setIsLoading({ loading: true, progress: 0 });

			let response;
			if (update) {
				response = await DataService.patch(`/newsletter-type/admin/${id}`, formData);
			} else {
				response = await DataService.post(`/newsletter-type/admin`, formData);
			}
			dispatch(newsletterTypesActions.addNewsletterType(response.data));
			SuccessToast("edited");
			setIsLoading({ loading: false, progress: 0 });
			navigate(-1);
		} catch (error) {
			setIsLoading({ loading: false, progress: 0 });
			WarningToast("An error has occurred");
			//console.log(error);
		}
	};
};

export const deleteNewsletterType = (id) => {
	return async (dispatch) => {
		try {
			const response = await DataService.delete(`/newsletter-type/admin/${id}`);
			if (response.status >= 200 && response.status < 300) {
				dispatch(getNewsletterTypes(pagination));
				SuccessToast("delete success");
			} else {
				WarningToast("An error has occur");
			}
		} catch (err) {
			WarningToast("An error has occur");
		}
	};
};