import { createSlice } from '@reduxjs/toolkit'
import { set } from 'immutable'

// First, define the reducer and action creators via `createSlice`
const imageCropperSlice = createSlice({
  name: 'imageCropper',
  initialState: {
    cropSRC: '',
    cropOpen: false,
    onSubmit: undefined,
    ratio: 1,
    allowFree: false,
  },
  reducers: {
    setCropSRC(state, action) {
      state.cropSRC = action.payload
    },
    setCropOpen(state, action) {
      state.cropOpen = action.payload
      if (!action.payload) {
        state.cropSRC = ''
        state.onSubmit = undefined
        state.ratio = 1
        state.allowFree = false

      }
    },
    cropImage(state, action) {
      // //console.log('cropImage', action.payload.cropSRC)
      state.cropSRC = action.payload.cropSRC;
      state.allowFree = action.payload.allowFree || false;
      // state.cropOpen = true;
      state.onSubmit = action.payload.onSubmit;
      state.ratio = action.payload.ratio;

    }
  }
})

// Destructure and export the plain action creators
export const { cropImage, setCropOpen } = imageCropperSlice.actions
export default imageCropperSlice.reducer

