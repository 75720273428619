import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { navigate } from "react-router-dom";
import { uploadFileS3 } from "../../utility/uploadFileS3";

const initialState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
  clubs: [],
  totalClubs: 0,
};
const pagin = {
  sort: "-createdAt",
  page: 1,
  limit: 10,
  type: "Combine",
};

const compoundSlice = createSlice({
  name: "compoundSlice",
  initialState,
  reducers: {
    setCompounds(state, action) {
      state.data = action.payload.compounds;
      state.total = action.payload.total;
    },
    setClubs(state, action) {
      state.clubs = action.payload.clubs;
      state.totalClubs = action.payload.total;
    },
    editCompound(state, action) {
      const index = state.data.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) state.data.push(action.payload);
      else state.data[index] = action.payload;
    },
  },
});

export const compoundActions = compoundSlice.actions;
export default compoundSlice.reducer;

export const getCompounds = (data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/compound/all?${new URLSearchParams(data).toString()}`
    );
    dispatch(compoundActions.setCompounds(response.data));
  };
};

export const getClubs = (data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/compound/club/all?${new URLSearchParams(data).toString()}`
    );
    dispatch(compoundActions.setClubs(response.data));
  };
};

export const editCompound = (id, data) => {
  return async (dispatch) => {
    const response = await DataService.patch(`/compound/${id}`, data);
    if (response.status >= 200 && response.status < 300) {
      dispatch(compoundActions.editCompound(response.data));
      SuccessToast("edited");
    } else {
      WarningToast("An error has occur");
    }
  };
};

export const editVenue = (id, data, setIsLoading) => {
  return async (dispatch) => {
    setIsLoading({ loading: true, progress: 0 });
    const response = await DataService.patch(`/compound/venue/${id}`, data);
    if (response.status >= 200 && response.status < 300) {
      dispatch(compoundActions.editCompound(response.data));
      SuccessToast("edited");
      setIsLoading({ loading: false, progress: 0 });
    } else {
      WarningToast("An error has occur");
    }
  };
};

export const editService = (id, data, setIsLoading) => {
  return async (dispatch) => {
    setIsLoading({ loading: true, progress: 0 });
    for (let i = 0; i < data.services.length; i++) {
      if (data.services[i]?.image?.images) {
        const images = await uploadFileS3(
          [data.services[i].image.images],
          setIsLoading
        );
        data.services[i].image = images[0];
      }
    }
    const response = await DataService.patch(
      `/compound/admin/service/${id}`,
      data
    );
    if (response.status >= 200 && response.status < 300) {
      dispatch(compoundActions.editCompound(response.data));
      SuccessToast("edited");
      setIsLoading({ loading: false, progress: 0 });
    } else {
      WarningToast("An error has occur");
    }
  };
};

export const addCompound = (
  compoundData,
  navigate,
  update,
  setIsLoading,
  to
) => {
  return async (dispatch) => {
    try {
      setIsLoading({ loading: true, progress: 0 });

      if (compoundData.files.masterPlanUrl) {
        const images = await uploadFileS3(
          [...compoundData.files.masterPlanUrl],
          setIsLoading
        );
        compoundData.masterPlanUrl = images[0];
      }
      if (compoundData.files.homeOwnerPDF) {
        const images = await uploadFileS3(
          [compoundData.files.homeOwnerPDF],
          setIsLoading
        );
        compoundData.homeOwnerPDF = images[0];
      }
      if (compoundData.files.clientHomePDF) {
        const images = await uploadFileS3(
          [compoundData.files.clientHomePDF],
          setIsLoading
        );
        compoundData.clientHomePDF = images[0];
      }
      if (compoundData.files.technicalPDF) {
        const images = await uploadFileS3(
          [compoundData.files.technicalPDF],
          setIsLoading
        );
        compoundData.designGuidelinesPDF = images[0];
      }
      if (compoundData?.files?.icon) {
        const images = await uploadFileS3(
          [...compoundData?.files?.icon],
          setIsLoading
        );
        compoundData.icon = images[0];
      }
      if (compoundData?.files?.entImage) {
        const images = await uploadFileS3(
          [...compoundData?.files?.entImage],
          setIsLoading
        );
        compoundData.entertainmentCoverImg = images[0];
      }
      if (compoundData?.files?.thumbnail) {
        const thumbnail = await uploadFileS3(
          [...compoundData?.files?.thumbnail],
          setIsLoading
        );
        compoundData.thumbnail = thumbnail[0];
      }
      if (compoundData?.files?.cardImage) {
        const images = await uploadFileS3(
          [compoundData?.files?.cardImage],
          setIsLoading
        );
        compoundData.cardImage = images[0];
      }
      if (compoundData?.files?.locationImgUrl) {
        //console.log(
        //   "compoundData.files.locationImgUrl",
        //   compoundData.files.locationImgUrl
        // );
        const images = await uploadFileS3(
          [...compoundData?.files?.locationImgUrl],
          setIsLoading
        );
        //console.log("locationImgUrl", images);
        compoundData.locationImgUrl = images[0];
      }
      for (let i = 0; i < compoundData.compoundServices.length; i++) {
        if (compoundData.compoundServices[i]?.url?.images) {
          // //console.log("compoundData.images[i].images", [compoundData.images[i].images]);
          const images = await uploadFileS3(
            [compoundData.compoundServices[i].url.images],
            setIsLoading
          );
          compoundData.compoundServices[i].url = images[0];
        }
      }
      if (compoundData.academySec?.image?.images) {
        const images = await uploadFileS3(
          [compoundData.academySec.image.images],
          setIsLoading
        );
        compoundData.academySec.image = images[0];
      }
      if (compoundData?.files?.prevImages.length > 0) {
        const images = await uploadFileS3(
          compoundData.files.prevImages,
          setIsLoading
        );
        compoundData.urls = [...compoundData.urls, ...images];
      }
      if (compoundData?.files?.interiorPrevImages.length > 0) {
        const images = await uploadFileS3(
          compoundData.files.interiorPrevImages,
          setIsLoading
        );
        compoundData.interiorURLS = [...compoundData.interiorURLS, ...images];
      }
      if (compoundData?.files?.exteriorPrevImages.length > 0) {
        const images = await uploadFileS3(
          compoundData.files.exteriorPrevImages,
          setIsLoading
        );
        compoundData.exteriorURLS = [...compoundData.exteriorURLS, ...images];
      }
      if (compoundData?.files?.videoPrev) {
        const images = await uploadFileS3(
          [compoundData.files.videoPrev],
          setIsLoading
        );
        compoundData.videoUrl = images[0];
      }
      if (
        compoundData?.files?.pdfsPrev?.length > 0 &&
        compoundData?.files?.pdfsPrev[0]
      ) {
        let oldFiles = compoundData.files.pdfsPrev;
        for (let i = 0; i < oldFiles.length; i++) {
          let isLink =
            typeof oldFiles[i] === "string" || oldFiles[i] instanceof String;

          if (!isLink) {
            const images = await uploadFileS3([oldFiles[i]], setIsLoading);
            oldFiles[i] = images[0];
          }
        }
        compoundData.pdfUrls = [...oldFiles];
      }

      delete compoundData.files;
      // //console.log("compoundData from slice", compoundData);
      let response;
      if (update) {
        response = await DataService.put(
          `/compound/${compoundData._id}`,
          compoundData
        );

        dispatch(compoundActions.editCompound(response.data));
      } else response = await DataService.post("/compound", compoundData);
      if (response.status >= 200 && response.status < 300) {
        dispatch(getCompounds(pagin));
        setIsLoading({ loading: false, progress: 0 });
        SuccessToast(update ? "Updated Successfully" : "Added Successfully");
        navigate(to);
      } else {
        WarningToast("An error has occurr");
        setIsLoading({ loading: false, progress: 0 });
      }
    } catch (err) {
      //console.log(err);
      WarningToast("An error has occurr");
      setIsLoading({ loading: false, progress: 0 });
    }
  };
};

export const deleteCompound = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(`/Compound/${id}`);
      if (response.status >= 200 && response.status < 300) {
        dispatch(getCompounds(pagin));
        SuccessToast("delete success");
      } else {
        WarningToast("An error has occur");
      }
    } catch (err) {
      WarningToast("An error has occur");
    }
  };
};

export const EditCompo = (
  id,
  formData,
  navigate,
  setIsLoading,
  update = false
) => {
  return async (dispatch) => {
    try {
      setIsLoading({ loading: true, progress: 0 });
      if (formData.files.compoImg) {
        const compoImg = await uploadFileS3(
          [...formData.files.compoImg],
          setIsLoading
        );
        formData.image = compoImg[0];
      }
      delete formData.files;
      let response;
      //console.log("formData", formData);
      if (update) {
        response = await DataService.patch(
          `/compound/admin/updateCompo/${id}`,
          formData
        );
      } else {
        // response = await DataService.post(`/rides/admin`, formData);
      }

      SuccessToast("edited");
      setIsLoading({ loading: false, progress: 0 });
      navigate(-1);
    } catch (error) {
      setIsLoading({ loading: false, progress: 0 });
      WarningToast("An error has occurred");
      //console.log(error);
    }
  };
};

export const deleteClub = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(`/Compound/${id}`);
      if (response.status >= 200 && response.status < 300) {
        dispatch(getClubs(pagin));
        SuccessToast("delete success");
      } else {
        WarningToast("An error has occur");
      }
    } catch (err) {
      WarningToast("An error has occur");
    }
  };
};

export const EditFacility = (formData, navigate, setIsLoading) => {
  return async (dispatch) => {
    try {
      setIsLoading({ loading: true, progress: 0 });
      for (let i = 0; i < formData.images.length; i++) {
        if (formData.images[i].images) {
          //console.log("formData.images[i].images", [formData.images[i].images]);
          const images = await uploadFileS3(
            [formData.images[i].images],
            setIsLoading
          );
          formData.images[i] = images[0];
        }
      }
      for (let i = 0; i < formData.extraSection.length; i++) {
        if (formData.extraSection[i]?.image?.images) {
          // //console.log("formData.images[i].images", [formData.images[i].images]);
          const images = await uploadFileS3(
            [formData.extraSection[i].image.images],
            setIsLoading
          );
          formData.extraSection[i].image = images[0];
        }
      }
      if (formData.philosophySection?.image?.images) {
        const images = await uploadFileS3(
          [formData.philosophySection.image.images],
          setIsLoading
        );
        formData.philosophySection.image = images[0];
      }
      if (formData.philosophySection?.brochure?.pdf) {
        const pdf = await uploadFileS3(
          [formData.philosophySection.brochure.pdf],
          setIsLoading
        );
        formData.philosophySection.brochure = pdf[0];
      }
      if (formData.locationSection?.image?.images) {
        const images = await uploadFileS3(
          [formData.locationSection.image.images],
          setIsLoading
        );
        formData.locationSection.image = images[0];
      }

      // let response;
      //console.log("formData", formData);
      await DataService.post(`/facility/admin`, formData);
      SuccessToast("edited");
      setIsLoading({ loading: false, progress: 0 });
      navigate("/apps/clubs");
    } catch (error) {
      setIsLoading({ loading: false, progress: 0 });
      WarningToast("An error has occurred");
      //console.log(error);
    }
  };
};
