import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";

const initialState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
};
const pagin = {
  sort: "-createdAt",
  page: 1,
  limit: 10,
};

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setEvents(state, action) {
      state.data = action.payload.communityEvent;
      state.total = action.payload.total;
    },
    editEvents(state, action) {
      const index = state.data.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) state.data.push(action.payload);
      else state.data[index] = action.payload;
    },
  },
});

export const eventsActions = eventsSlice.actions;
export default eventsSlice.reducer;

export const getEvents = (data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/community-events/all?${new URLSearchParams(data).toString()}`
    );
    dispatch(eventsActions.setEvents(response.data));
  };
};

export const editEvents = (id, data) => {
  return async (dispatch) => {
    const response = await DataService.patch(`/community-events/${id}`, data);
    if (response.status >= 200 && response.status < 300) {
      dispatch(eventsActions.editEvents(response.data));
      SuccessToast("edited");
    } else {
      WarningToast("An error has occur");
    }
  };
};

export const addEvents = (data, navigate, update, setIsLoading) => {
  return async (dispatch) => {
    try {
      if (data.eventcover) {
        const uploadurls = await uploadFileS3(data.eventcover, setIsLoading);
        data.cover = uploadurls[0];
        delete data.eventcover;
      }
      let response;
      if (update) {
        response = await DataService.patch(
          `/community-events/${data._id}`,
          data
        );
      } else response = await DataService.post("/community-events", data);
      if (response.status >= 200 && response.status < 300) {
        dispatch(getEvents(pagin));
        SuccessToast("Added Successfully");
        setIsLoading({ loading: false, progress: 0 });
        navigate(-1);
      } else {
        WarningToast("An error has occurr");
        setIsLoading({ loading: false, progress: 0 });
      }
    } catch (err) {
      WarningToast("An error has occurr");
      setIsLoading({ loading: false, progress: 0 });
    }
  };
};
export const deletEvents = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(`/community-events/${id}`);
      if (response.status >= 200 && response.status < 300) {
        dispatch(getEvents(pagin));
        SuccessToast("delete success");
      } else {
        WarningToast("An error has occur");
      }
    } catch (err) {
      //console.log("err", err);
      WarningToast("An error has occur");
    }
  };
};
