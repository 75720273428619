import axios from "axios";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";


const { createSlice } = require("@reduxjs/toolkit");

const InitialState = {
  data: [],
  total: 0,
  categories: [],
  totalCategories: 0,
};

const pagination = {
  sort: "-createdAt",
  page: 1,
  limit: 10,
};

const faqsSlice = createSlice({
  name: "faqs",
  initialState: InitialState,
  reducers: {
    setFaq(state, action) {
      state.data = action.payload.faqs;
      state.total = action.payload.total;
    },
    setFaqCategories(state, action) {
      state.categories = action.payload.faqs;
      state.totalCategories = action.payload.total;
    },
    setFaqCategoriesOnly(state, action) {
      state.categories = action.payload;
    },
    addFaq(state, action) {
      state.data.unshift(action.payload);
    },
    addFaqCategory(state, action) {
      state.categories.unshift(action.payload);
    },
  },
});


export const faqsActions = faqsSlice.actions;
export default faqsSlice.reducer;

export const getFAQS = (data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/faq/admin/all?${new URLSearchParams(data).toString()}`
    );
    if (response.status >= 200 && response.status < 300)
      dispatch(faqsActions.setFaq(response.data));
    else WarningToast("An error has occur");
  };
};
export const getFAQSCategories = (data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/faq/category/admin/all?${new URLSearchParams(data).toString()}`
    );
    if (response.status >= 200 && response.status < 300)
      dispatch(faqsActions.setFaqCategories(response.data));
    else WarningToast("An error has occur");
  };
};
export const getAllFAQSCategories = () => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/faq/category/`
    );
    if (response.status >= 200 && response.status < 300)
      dispatch(faqsActions.setFaqCategoriesOnly(response.data));
    else WarningToast("An error has occur");
  };
};
export const AddFAQ = (
  id,
  formData,
  navigate,
  setIsLoading,
  update = false
) => {
  return async (dispatch) => {
    try {
      setIsLoading({ loading: true, progress: 0 });

      let response;
      if (update) {
        response = await DataService.patch(`/faq/admin/${id}`, formData);
      } else {
        response = await DataService.post(`/faq/admin`, formData);
      }
      dispatch(faqsActions.addFaq(response.data));
      SuccessToast("edited");
      setIsLoading({ loading: false, progress: 0 });
      navigate(-1);
    } catch (error) {
      setIsLoading({ loading: false, progress: 0 });
      WarningToast("An error has occurred");
      //console.log(error);
    }
  };
};
export const AddFAQCategory = (
  id,
  formData,
  navigate,
  setIsLoading,
  update = false
) => {
  return async (dispatch) => {
    try {
      setIsLoading({ loading: true, progress: 0 });

      let response;
      if (update) {
        response = await DataService.patch(`/faq/category/admin/${id}`, formData);
      } else {
        response = await DataService.post(`/faq/category/admin`, formData);
      }
      dispatch(faqsActions.addFaqCategory(response.data));
      SuccessToast("edited");
      setIsLoading({ loading: false, progress: 0 });
      navigate(-1);
    } catch (error) {
      setIsLoading({ loading: false, progress: 0 });
      WarningToast("An error has occurred");
      //console.log(error);
    }
  };
};
export const deleteFAQ = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(`/faq/admin/${id}`);
      if (response.status >= 200 && response.status < 300) {
        dispatch(getFAQS(pagination));
        SuccessToast("delete success");
      } else {
        WarningToast("An error has occur");
      }
    } catch (err) {
      WarningToast("An error has occur");
    }
  };
};
export const deleteFAQCategory = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(`/faq/category/admin/${id}`);
      if (response.status >= 200 && response.status < 300) {
        dispatch(getFAQSCategories(pagination));
        SuccessToast("delete success");
      } else {
        WarningToast("An error has occur");
      }
    } catch (err) {
      WarningToast("An error has occur");
    }
  };
};