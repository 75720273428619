import axios from "axios";
import { SuccessToast, WarningToast } from "../../utility/toast";
let refreshPromise = null

// const API_ENDPOINT = "https://api.oraegdev.com"; //process.env.REACT_APP_API_ENDPOINT;
// const API_ENDPOINT = "https://ora-apitest.smartlink.lol"; //process.env.REACT_APP_API_ENDPOINT;
const API_ENDPOINT = "https://api-v2.oraegdev.com"; //process.env.REACT_APP_API_ENDPOINT;
// const API_ENDPOINT = "https://ora-api.codpark.com"; //process.env.REACT_APP_API_ENDPOINT;
// const API_ENDPOINT = "http://localhost:3008";
const authHeader = () => ({
  Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
});
const client = axios.create({
  baseURL: API_ENDPOINT,
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
    entity: "ora",
    "Content-Type": "application/json",
  },
});
const getRequestConfig = (args) => {
  if (typeof args === 'string') {
    return { url: args }
  }

  return args
}
client.interceptors.response.use(
  // this is the response interceptor
  function (response) {
    return response;
  },
  // this is the error interceptor
  function (error) {
    return new Promise(async (resolve, reject) => {
      if (error.response?.status === 408) {
        window.localStorage.removeItem('accessToken')
        window.localStorage.removeItem('refreshToken')
        window.localStorage.removeItem('userData')
        window.location.href = '/login'
      }
      if (error.response?.status === 403 || error.response?.status === 401) {
        ///refresh access token
        if (!refreshPromise) {
          refreshPromise = axios.get(`${API_ENDPOINT}/staff/refresh`, {
            headers: {
              Authorization: `${window.localStorage.getItem('refreshToken')}`,
              'x-csrf-token': window.localStorage.getItem('csrf')
            }
          })
        }
        try {
          ////await refresh from self or other requests
          const response = await refreshPromise
          window.localStorage.setItem('accessToken', response.data.token)
          window.localStorage.setItem('refreshToken', response.data.refreshToken)

          ////retry the original request
          const requestConfig = getRequestConfig(error.config)
          refreshPromise = null
          const result = await axios({
            ...requestConfig,
            withCredentials: true,
            cors: true,
            headers: { ...requestConfig.headers, Authorization: `Bearer ${response.data.token}` },
          })

          resolve(result)
        } catch (e) {
          if (e.response?.status === 401) {
            window.localStorage.removeItem('accessToken')
            window.localStorage.removeItem('refreshToken')
            window.localStorage.removeItem('userData')
            window.location.href = '/login'

            reject(e)
          } else if (e.response?.status === 403) {
            reject(e)
            window.location.reload()
          }
        }
      }
      reject(error)
    })
  }
);
class DataService {
  static get(path = "") {
    return client({
      method: "GET",
      url: path,
      headers: { ...authHeader() },
    });
  }

  static post(path = "", data = {}, optionalHeader = {}) {
    return client({
      method: "POST",
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }
  static delete(path = "", data = {}, optionalHeader = {}) {
    return client({
      method: "DELETE",
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static patch(path = "", data = {}) {
    return client({
      method: "PATCH",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = "", data = {}) {
    return client({
      method: "PUT",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }
  static putFormData(path = "", data = {}) {
    return client({
      method: "PUT",
      url: path,
      data,
      headers: { ...authHeader() },
    });
  }
}


/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = {
    ...headers,
    Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
  };

  return requestConfig;
});

const pagin = {
  sort: "-createdAt",
  page: 1,
  limit: 10,
};

export const upload = (ExcelSheet, baseurl, getterFunction, setIsLoading) => {
  return async (dispatch) => {
    try {
      setIsLoading({ loading: true, progress: 0 });
      const response = await axios.post(
        `${API_ENDPOINT}${baseurl}`,
        ExcelSheet,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
            entity: "ora",
          },
          onUploadProgress: (e) => {
            setIsLoading({
              loading: true,
              progress: Math.trunc((e.loaded / e.total) * 100),
              uploaded: false,
            });
          },
        }
      );
      SuccessToast("Upload success");
      if (getterFunction) { dispatch(getterFunction(pagin)); }
      setIsLoading({ loading: false, progress: 0, uploaded: true });
    } catch (err) {
      WarningToast(err?.response?.data?.message);
      //console.log(err);
      setIsLoading({ loading: false, progress: 0 });
    }
  };
};

// client.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     /**
//      * Do something in case the response returns an error code [3**, 4**, 5**] etc
//      * For example, on token expiration retrieve a new access token, retry a failed request etc
//      */
//     const { response } = error;
//     const originalRequest = error.config;
//     if (response) {
//       if (response.status === 500) {
//         // do something here
//       } else {
//         return originalRequest;
//       }
//     }
//     return Promise.reject(error);
//   }
// );
export { DataService };
