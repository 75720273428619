import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";

const initialState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
  unRead: 0,
};

const checkSlice = createSlice({
  name: "Checks",
  initialState,
  reducers: {
    setChecks(state, action) {
      state.data = action.payload.checks;
      state.total = action.payload.total;
    },
  },
});

export const checkActions = checkSlice.actions;
export default checkSlice.reducer;

export const getNewChecks = (data) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `/check?${new URLSearchParams(data).toString()}`
      );
      if (response.status >= 200 && response.status < 300) {
        dispatch(checkActions.setChecks(response.data));
      }
    } catch (e) {
      WarningToast(e.response.data.message);
    }
  };
};
// edit checks
export const editCheck = (id, data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await DataService.patch(`/check/confirm/${id}`, data);
      if (response.status >= 200 && response.status < 300) {
        dispatch(getNewChecks());
        SuccessToast("edited");
        navigate(-1);
      } else {
        WarningToast("An error has occur");
      }
    } catch (e) {
      WarningToast("An error has occur", e);
    }
  };
};
