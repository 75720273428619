import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { WarningToast, SuccessToast } from "../../utility/toast";
import { getStatistics } from "../statistics/statisticsSlice";
import { navigate } from 'react-router-dom';

const initialState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
  unRead: 0,
};
const serviceRequestSlice = createSlice({
  name: "serviceRequest",
  initialState: initialState,
  reducers: {
    setServiceRequest(state, action) {
      state.data = action.payload.requests;
      state.total = action.payload.total;
    },
    editServiceRequest(state, action) {
      const index = state.data.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) state.data.push(action.payload);
      else state.data[index] = action.payload;
    },
    setUnRead(state, action) {
      state.unRead = action.payload;
    },
  },
});
export const serviceRequestAction = serviceRequestSlice.actions;
export default serviceRequestSlice.reducer;

export const getServiceRequests = (data) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `/services/all-requests?${new URLSearchParams(data).toString()}`
      );
      if (response.status >= 200 && response.status < 300) {
        dispatch(serviceRequestAction.setServiceRequest(response.data));
        // SuccessToast("")
      } else {
        //console.log("err1");
        WarningToast("An error has occur");
      }
    } catch (e) {
      WarningToast("An error has occur", e);
    }
  };
};
export const editServiceRequest = (
  id,
  formData,
  navigate,
  setIsLoading,
) => {
  return async (dispatch) => {
    try {
      setIsLoading({ loading: true, progress: 0 });
      // let response;
      await DataService.patch(`/services/request/${id}`, formData)

      SuccessToast("edited");
      setIsLoading({ loading: false, progress: 0 });
      dispatch(getStatistics());
      navigate(-1);
    } catch (error) {
      setIsLoading({ loading: false, progress: 0 });
      WarningToast("An error has occurred");
      //console.log(error);
    }
  };
};
