import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";

const initialState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
};
const pagin = {
  sort: "-createdAt",
  page: 1,
  limit: 10,
};

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setNews(state, action) {
      state.data = action.payload.news;
      state.total = action.payload.total;
    },
    editNews(state, action) {
      const index = state.data.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) state.data.push(action.payload);
      else state.data[index] = action.payload;
    },
  },
});

export const newsActions = newsSlice.actions;
export default newsSlice.reducer;

export const getNews = (data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/news/all?${new URLSearchParams(data).toString()}`
    );
    dispatch(newsActions.setNews(response.data));
  };
};

export const editNews = (id, data) => {
  return async (dispatch) => {
    const response = await DataService.patch(`/news/${id}`, data);
    if (response.status >= 200 && response.status < 300) {
      dispatch(newsActions.editNews(response.data));
      SuccessToast("edited");
    } else {
      WarningToast("An error has occur");
    }
  };
};

export const addnews = (data, navigate, update, setIsLoading) => {
  return async (dispatch) => {
    try {
      if (data.newcover) {
        const uploadurls = await uploadFileS3(data.newcover, setIsLoading);
        data.cover = uploadurls[0];
        delete data.newcover;
      }
      if (data?.fileImg?.cardImg) {
        const cardImg = await uploadFileS3([...data.fileImg.cardImg], setIsLoading);
        data.cardImage = cardImg[0];
      }
      for (let i = 0; i < data?.section?.length; i++) {
				if (data.section[i]?.image?.images) {
					// //console.log("formData.images[i].images", [formData.images[i].images]);
					const images = await uploadFileS3([data.section[i].image.images], setIsLoading);
					data.section[i].image = images[0];
				}
			}

      delete data.fileImg;
      let response;
      if (update) {
        response = await DataService.patch(`/news/${data._id}`, data);
        // navigate("/apps/news");
      } else response = await DataService.post("/news", data);
      if (response.status >= 200 && response.status < 300) {
        dispatch(getNews(pagin));
        SuccessToast("Added Successfully");
        setIsLoading({ loading: false, progress: 0 });
        navigate("/apps/news");
      } else {
        WarningToast("An error has occurr");
        setIsLoading({ loading: false, progress: 0 });
      }
    } catch (err) {
      console.log(err);
      WarningToast("An error has occurr");
      setIsLoading({ loading: false, progress: 0 });
    }
  };
};
export const deletenews = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(`/news/${id}`);
      if (response.status >= 200 && response.status < 300) {
        dispatch(getNews(pagin));
        SuccessToast("delete success");
      } else {
        WarningToast("An error has occur");
      }
    } catch (err) {
      WarningToast("An error has occur");
    }
  };
};
