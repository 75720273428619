import axios from "axios";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";

const { createSlice } = require("@reduxjs/toolkit");

const InitialState = {
  data: [],
  total: 0,
};

const pagination = {
  sort: "-createdAt",
  page: 1,
  limit: 10,
};

const directorySlice = createSlice({
  name: "directory",
  initialState: InitialState,
  reducers: {
    setDirectory(state, action) {
      state.data = action.payload.directories;
      state.total = action.payload.total;
      //console.log("payload", action.payload.total);
    },
    addDirectory(state, action) {
      state.data.unshift(action.payload);
    },
    editDirectory(state, action) {
      const index = state.data.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) state.data.push(action.payload);
      else state.data[index] = action.payload;
    },
  },
});

export const directoryActions = directorySlice.actions;
export default directorySlice.reducer;

export const getDirectory = (data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/directory/admin/all?${new URLSearchParams(data).toString()}`
    );
    //console.log("response", response);
    if (response.status >= 200 && response.status < 300)
      dispatch(directoryActions.setDirectory(response.data));
    else WarningToast("An error has occur");
  };
};

export const AddDirectory = (
  id,
  formData,
  navigate,
  setIsLoading,
  update = false
) => {
  return async (dispatch) => {
    try {
      setIsLoading({ loading: true, progress: 0 });
      if (formData.files.icon) {
        const icon = await uploadFileS3([...formData.files.icon], setIsLoading);
        formData.icon = icon[0];
      }
      let response;
      //console.log("formData", formData);
      if (update) {
        response = await DataService.patch(`/directory/admin/${id}`, formData);
      } else {
        response = await DataService.post(`/directory/admin`, formData);
      }
      dispatch(directoryActions.addDirectory(response.data));
      SuccessToast("edited");
      setIsLoading({ loading: false, progress: 0 });
      navigate(-1);
    } catch (error) {
      setIsLoading({ loading: false, progress: 0 });
      WarningToast("An error has occurred");
      //console.log(error);
    }
  };
};

export const deleteDirectory = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(`/directory/admin/${id}`);
      if (response.status >= 200 && response.status < 300) {
        dispatch(getDirectory(pagination));
        SuccessToast("delete success");
      } else {
        WarningToast("An error has occur");
      }
    } catch (err) {
      WarningToast("An error has occur");
    }
  };
};
