import axios from "axios";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";


const { createSlice } = require("@reduxjs/toolkit");

const InitialState = {
  data: [],
  total: 0,
};

const pagination = {
  sort: "-createdAt",
  page: 1,
  limit: 10,
};

const academySlice = createSlice({
  name: "academy",
  initialState: InitialState,
  reducers: {
    setAcademy(state, action) {
      state.data = action.payload.academies;
      state.total = action.payload.total;
      // //console.log("payload", action.payload.total);
    },
    addAcademy(state, action) {
      state.data.unshift(action.payload);
    },
    editAcademy(state, action) {
      const index = state.data.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) state.data.push(action.payload);
      else state.data[index] = action.payload;
    },
  },
});


export const academyActions = academySlice.actions;
export default academySlice.reducer;

export const getAcademy = (data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/academy/admin/all?${new URLSearchParams(data).toString()}`
    );
    //console.log("response", response);
    if (response.status >= 200 && response.status < 300)
      dispatch(academyActions.setAcademy(response.data));
    else WarningToast("An error has occur");
  };
};

export const AddAcademy = (
  id,
  formData,
  navigate,
  setIsLoading,
  update = false
) => {
  return async (dispatch) => {
    //console.log("formData out", formData);
    try {
      setIsLoading({ loading: true, progress: 0 });
      if (formData.files.iconImage) {
        //console.log("formData.files.iconImage", [...formData.files.iconImage]);
        const iconImage = await uploadFileS3([...formData.files.iconImage], setIsLoading);
        formData.icon = iconImage[0];
      }
      if (formData.files.blackIconImage) {
        const blackIconImage = await uploadFileS3([...formData.files.blackIconImage], setIsLoading);
        formData.blackIcon = blackIconImage[0];
      }
      if (formData.files.cardImage) {
        const cardImage = await uploadFileS3([...formData.files.cardImage], setIsLoading);
        formData.cardImage = cardImage[0];
      }
      for (let i = 0; i < formData.images.length; i++) {
        if (formData.images[i].images) {
          //console.log("formData.images[i].images", [formData.images[i].images]);
          const images = await uploadFileS3([formData.images[i].images], setIsLoading);
          formData.images[i] = images[0];
        }
      }
      for (let i = 0; i < formData.partner.length; i++) {
        if (formData.partner[i]?.image?.images) {
          const images = await uploadFileS3([formData.partner[i].image.images], setIsLoading);
          formData.partner[i].image = images[0];
        }
      }
      if (formData?.files?.videoPrev) {
        const video = await uploadFileS3([formData.files.videoPrev], setIsLoading);
        //console.log("video ==>", video[0])
        formData.video = video[0];
      }
      delete formData.files;
      //console.log("formData", formData);
      let response;
      //console.log("formData", formData);
      if (update) {
        response = await DataService.patch(`/academy/admin/${id}`, formData);
      } else {
        response = await DataService.post(`/academy/admin`, formData);
      }
      dispatch(academyActions.addAcademy(response.data));
      SuccessToast("edited");
      setIsLoading({ loading: false, progress: 0 });
      navigate(-1);
    } catch (error) {
      setIsLoading({ loading: false, progress: 0 });
      WarningToast("An error has occurred");
      //console.log(error);
    }
  };
};

export const deleteAcademy = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(`/academy/admin/${id}`);
      if (response.status >= 200 && response.status < 300) {
        dispatch(getAcademy(pagination));
        SuccessToast("delete success");
      } else {
        WarningToast("An error has occur");
      }
    } catch (err) {
      WarningToast("An error has occur");
    }
  };
};