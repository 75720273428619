// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
// import auth from './authentication'
// import todo from "@src/views/apps/todo/store";
// import chat from "@src/views/apps/chat/store";
// import users from '@src/views/apps/user/store'
// import email from "@src/views/apps/email/store";
// import invoice from "@src/views/apps/invoice/store";
// import calendar from "@src/views/apps/calendar/store";
// import ecommerce from "@src/views/apps/ecommerce/store";
// import dataTables from "@src/views/tables/data-tables/store";
// import permissions from "@src/views/apps/roles-permissions/store";

// import Contacts from "./contact-us/reducers";
import complaintReducer from "./complaint/complaintSlice";
import constructionsReducer from "./constructions-update/constructionsSlice";
import contactReducer from "./contact-us/contactSlice";
import uniteReducer from "./unit/uniteSlice";
import serviceRequestSlice from "./repair/serviceRequestSlice";
import statisticsReducer from "./statistics/statisticsSlice";
import unitCategoryReducer from "./unit-category/unitCategorySlice";
import serviceReducer from "./services/serviceSlice";
import checkReducer from "./check/checkSlice";
import usersReducer from "./users/usersSlice";
import gateInvitationReducer from "./gate-invitation/gateInvitationSlice";
import compoundReducer from "./compound/compoundSlice";
import newsReducer from "./news/newsSlice";
import authReducer from "./authentication/authSlice";
import jobMonitorReducer from "./job-monitor/jobMonitorSlice";
import phasesSlice from "./phases/phasesSlice";
import stellerSlice from "./steller/stellerSlice";
import stellerproductSlic from "./steller/stellerproductSlic";
import eventsSlice from "./comunity-events/eventsSlice";
import venuesSlice from "./venues/venuesSlice";
import eventTypesSlice from "./eventType/eventTypesSlice";
import newEventsSlice from "./new-events/newEventsSlice";
import ridesSlice from "./rides/ridesSlice";
import configPageSlice from "./configPage/configPageSlice";
import registerInterestsSlice from "./register-interest/registerInterestSlice";
import careerSlice from "./career/careerSlice";
import teamsSlice from "./teams/teamsSlice";
import academySlice from "./academy/academySlice";
import requestEventSlice from "./requestEvent/requestEventSlice";
import aboutSlice from "./about/aboutSlice";
import newsletterSlice from "./news-letter/newsletterSlice";
import orderSlice from "./order/orderSlice";
import directorySlice from "./directory/directorySlice";
import imageCropperSlice from "./cropper/imageCropperSlice";
import newsletterTypesSlice from "./newsletterType/newsletterTypeSlice";
import popupImgSlice from "./popupImg/popupImgSlice";

import billSlice from "./bill/billSlice";
import faqSlice from "./faq/faqSlice";
import notifyMonitorSlice from "./notify-monitor/notifyMonitorSlice";
import compoundLocationSlice from "./compoundLocation/compoundLocationSlice";
import parkAccessSlice from "./parkAccess/parkAccessSlice";
import categoryTypeSlice from "./categoryType/categoryTypeSlice";
import markerCategorySlice from "./markerCategory/markerCategorySlice";
import markerSlice from "./marker/markerSlice";
import mobileConfigSlice from "./mobileConfig/mobileConfigSlice";

import parkTicketsSlice from "./park-tickets/parkTicketsSlice";
const rootReducer = {
  // auth,
  events: eventsSlice,
  stellerProduct: stellerproductSlic,
  steller: stellerSlice,
  phases: phasesSlice,
  contactUs: contactReducer,
  statistics: statisticsReducer,
  constructions: constructionsReducer,
  newComplaint: complaintReducer,
  auth: authReducer,
  checks: checkReducer,
  unite: uniteReducer,
  unitCategory: unitCategoryReducer,
  users: usersReducer,
  gateInvitation: gateInvitationReducer,
  services: serviceReducer,
  compound: compoundReducer,
  news: newsReducer,
  jobMonitor: jobMonitorReducer,
  serviceRequest: serviceRequestSlice,
  venues: venuesSlice,
  eventTypes: eventTypesSlice,
  newEvents: newEventsSlice,
  rides: ridesSlice,
  configPages: configPageSlice,
  mobileConfig: mobileConfigSlice,
  registerInterests: registerInterestsSlice,
  career: careerSlice,
  teams: teamsSlice,
  academy: academySlice,
  requestEvent: requestEventSlice,
  about: aboutSlice,
  newsletter: newsletterSlice,
  orders: orderSlice,
  newsletterTypes: newsletterTypesSlice,
  popupImg: popupImgSlice,
  bills: billSlice,
  parkAccess: parkAccessSlice,
  faqs: faqSlice,
  notifyMonitor: notifyMonitorSlice,
  compoundLocations: compoundLocationSlice,
  categoryType: categoryTypeSlice,
  markerCategory: markerCategorySlice,
  marker: markerSlice,
  parkTickets: parkTicketsSlice,
  // todo,
  // chat,
  // email,
  // users,
  navbar,
  layout,
  // invoice,
  // calendar,
  // ecommerce,
  // dataTables,
  // permissions,
  directory: directorySlice,
  imageCropper: imageCropperSlice
};

export default rootReducer;
