import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";
import { uploadFileS3 } from "../../utility/uploadFileS3";

const initialState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
  searchField: {
    name: "",
    value: "",
  },
};

const pagin = {
  sort: "-createdAt",
  page: 1,
  limit: 10,
};

const unitCategorySlice = createSlice({
  name: "unitCategorySlice",
  initialState,
  reducers: {
    setUnitCategory(state, action) {
      state.data = action.payload.category;
      state.total = action.payload.total;
    },
    setSearchField(state, action) {
      state.searchField = action.payload;
    },
    editUnitCategory(state, action) {
      const index = state.data.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) state.data.push(action.payload);
      else state.data[index] = action.payload;
    },
  },
});

export const unitCategoryActions = unitCategorySlice.actions;
export default unitCategorySlice.reducer;

export const getUnitCategory = (data) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `/unit-category/all?${new URLSearchParams(data).toString()}`
      );
      dispatch(unitCategoryActions.setUnitCategory(response.data));
    } catch (err) {
      WarningToast(err.response.data.message);
    }
  };
};

export const addUnitCategory = (
  unitCatData,
  navigate,
  update,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      setIsLoading({ loading: true, progress: 0 });
      // if (unitCatData.files.length > 0) {
      //   const uploadurls = await uploadFileS3(unitCatData.files, setIsLoading);
      //   unitCatData.urls = [...unitCatData.urls, ...uploadurls];
      // }
      for (let i = 0; i < unitCatData.urls.length; i++) {
        if (unitCatData.urls[i].images) {
          //console.log("unitCatData.images[i].images", [unitCatData.urls[i].images]);
          const urls = await uploadFileS3(
            [unitCatData.urls[i].images],
            setIsLoading
          );
          unitCatData.urls[i] = urls[0];
        }
      }
      if (unitCatData.fileImg.floorPlanImg) {
        const floorPlanImg = await uploadFileS3(
          [...unitCatData.fileImg.floorPlanImg],
          setIsLoading
        );
        unitCatData.floorPlanImg = floorPlanImg[0];
      }
      if (unitCatData.fileImg.cardImg) {
        const cardImg = await uploadFileS3(
          [...unitCatData.fileImg.cardImg],
          setIsLoading
        );
        unitCatData.cardImage = cardImg[0];
      }
      if (unitCatData.fileImg.depImg) {
        const depImg = await uploadFileS3(
          [...unitCatData.fileImg.depImg],
          setIsLoading
        );
        unitCatData.departmentDetails.image = depImg[0];
      }
      if (unitCatData.fileImg.designGuidelinesPDF) {
        const designGuidelinesPDF = await uploadFileS3(
          [unitCatData.fileImg.designGuidelinesPDF],
          setIsLoading
        );
        unitCatData.technicalPDF = designGuidelinesPDF[0];
      }
      if (unitCatData.brochure?.pdf) {
        const brochure = await uploadFileS3(
          [unitCatData.brochure.pdf],
          setIsLoading
        );
        unitCatData.brochure = brochure[0];
      }
      // techSpecsPdf
      if (unitCatData.techSpecsPdf?.pdf) {
        const techSpecsPdf = await uploadFileS3(
          [unitCatData.techSpecsPdf.pdf],
          setIsLoading
        );
        unitCatData.techSpecsPdf = techSpecsPdf[0];
      }
      delete unitCatData.fileImg;

      let response;
      if (update) {
        response = await DataService.put(
          `/unit-category/${unitCatData._id}`,
          unitCatData
        );
        dispatch(unitCategoryActions.editUnitCategory(response.data));
      } else response = await DataService.post("/unit-category", unitCatData);
      if (response.status >= 200 && response.status < 300) {
        dispatch(getUnitCategory(pagin));
        setIsLoading({ loading: false, progress: 0 });
        SuccessToast(update ? "Updated Successfully" : "Added Successfully");
        navigate("/apps/unit-category");
      } else {
        WarningToast("An error has occurr");
        setIsLoading({ loading: false, progress: 0 });
      }
    } catch (err) {
      //console.log(err.response.data);
      WarningToast("An error has occurr");
      setIsLoading({ loading: false, progress: 0 });
    }
  };
};

export const deleteUniteCategory = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(`/unit-category/${id}`);
      if (response.status >= 200 && response.status < 300) {
        dispatch(getUnitCategory(pagin));
        SuccessToast("delete success");
      } else {
        WarningToast("An error has occur");
      }
    } catch (err) {
      WarningToast("An error has occur");
    }
  };
};
