import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "../../configs/dataService/dataService";
import { SuccessToast, WarningToast } from "../../utility/toast";

const initialState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
};

const gateInvitationSlice = createSlice({
  name: "gateInvitation",
  initialState,
  reducers: {
    setGateInvitations(state, action) {
      state.data = action.payload.gateInvitations;
      state.total = action.payload.total;
    },
    editGateInvitation(state, action) {
      const index = state.data.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) state.data.push(action.payload);
      else state.data[index] = action.payload;
    },
  },
});

export const gateInvitationActions = gateInvitationSlice.actions;
export default gateInvitationSlice.reducer;

export const getGateInvitations = (data) => {
  return async (dispatch) => {
    const response = await DataService.get(
      `/gate-invitation/all?${new URLSearchParams(data).toString()}`
    );
    //console.log(response.data);
    dispatch(gateInvitationActions.setGateInvitations(response.data));
  };
};

export const editGateInvitation = (id, data) => {
  return async (dispatch) => {
    const response = await DataService.patch(`/gate-invitation/${id}`, data);
    dispatch(getStatistics());
    if (response.status >= 200 && response.status < 300) {
      dispatch(gateInvitationActions.editGateInvitation(response.data));
      SuccessToast("edited");
    } else {
      WarningToast("An error has occur");
    }
  };
};
